import { useSingleSourceStore } from '../store/single-source-store/single-source-store';
import { Status } from '@xspecs/single-source-model';
import { useDebouncedValue } from '@mantine/hooks';

export const useFileIsDisconnected = (id?: string) => {
  const fileStatusesById = useSingleSourceStore.use.filesById();
  const fileStatus = id ? fileStatusesById[id]?.status : undefined;

  const isDisconnected = fileStatus === Status.Disconnected;

  const [debouncedDisconnected] = useDebouncedValue(isDisconnected, 2000);

  return { disconnected: debouncedDisconnected } as const;
};
