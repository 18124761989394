/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: any;
};

export type AcceptInviteArgs = {
  invitationId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type AddFileArgs = {
  fileType: FileType;
  id: Scalars['String'];
  name: Scalars['String'];
  parent?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type AddOrganizationArgs = {
  name: Scalars['String'];
  organizationId: Scalars['String'];
};

export type AddWorkspaceArgs = {
  organizationId: Scalars['String'];
  workspaceId: Scalars['String'];
  workspaceName: Scalars['String'];
};

export type AssetEntity = {
  __typename?: 'AssetEntity';
  dataSource: Scalars['String'];
  description: Scalars['String'];
  detailsPane?: Maybe<Array<DetailsElement>>;
  icon: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
};

export type Change = {
  __typename?: 'Change';
  blockId: Scalars['String'];
  changeType?: Maybe<ChangeType>;
  changeset?: Maybe<Changeset>;
  content?: Maybe<Scalars['String']>;
  file: File;
  fileVersion: Scalars['String'];
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  line?: Maybe<Scalars['Int']>;
  path?: Maybe<Array<Scalars['Int']>>;
};

export enum ChangeType {
  Added = 'Added',
  Deleted = 'Deleted',
  Modified = 'Modified',
}

export type Changeset = {
  __typename?: 'Changeset';
  createdAt: Scalars['DateTimeISO'];
  files?: Maybe<Array<FileChange>>;
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ChangesetTree = {
  __typename?: 'ChangesetTree';
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  name: Scalars['String'];
  parent?: Maybe<ChangesetTree>;
  type: ChangesetTreeType;
};

export enum ChangesetTreeType {
  BusinessRuleSpec = 'BusinessRuleSpec',
  Changeset = 'Changeset',
  ComponentSpec = 'ComponentSpec',
  Doc = 'Doc',
}

export type CommittedChange = {
  __typename?: 'CommittedChange';
  blockId: Scalars['String'];
  changeType?: Maybe<ChangeType>;
  changeset: Changeset;
  content?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['Int']>;
  path: Array<Scalars['Int']>;
};

export type ConfigureSamlSsoArgs = {
  idp: Scalars['String'];
  idpMetaDataUrl: Scalars['String'];
  organizationId: Scalars['String'];
  tenantId: Scalars['String'];
};

export type ConstructEntity = {
  __typename?: 'ConstructEntity';
  backgroundColor: Scalars['String'];
  description: Scalars['String'];
  detailsPane?: Maybe<Array<DetailsElement>>;
  label: Scalars['String'];
  shape: Scalars['String'];
  textColor: Scalars['String'];
  type: Scalars['String'];
};

export enum ContentSharingScope {
  Anyone = 'Anyone',
  Organization = 'Organization',
}

export type ContinueModelSuggestionArgs = {
  clientId: Scalars['String'];
  modelId: Scalars['String'];
  modelType: Scalars['String'];
  organizationId: Scalars['String'];
  selections: Array<SelectionsInput>;
  userPrompt: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type DeleteFileArgs = {
  id: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type DeleteOrganizationArgs = {
  organizationId: Scalars['String'];
};

export type DeleteWorkspaceArgs = {
  organizationId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type DetailsElement = {
  __typename?: 'DetailsElement';
  elements?: Maybe<Array<DetailsElement>>;
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Option>>;
  type: DetailsElementType;
  value?: Maybe<Scalars['String']>;
};

export enum DetailsElementType {
  Select = 'Select',
  Tab = 'Tab',
  Tabs = 'Tabs',
  Textbox = 'Textbox',
}

export type EntityCategory = {
  __typename?: 'EntityCategory';
  assets?: Maybe<Array<AssetEntity>>;
  constructs?: Maybe<Array<ConstructEntity>>;
  name: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  children?: Maybe<Array<File>>;
  fileType: FileType;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<File>;
};

export type FileChange = {
  __typename?: 'FileChange';
  committedChanges?: Maybe<Array<CommittedChange>>;
  fileType: FileType;
  id: Scalars['ID'];
  name: Scalars['String'];
  publishedChanges: Array<Change>;
};

export enum FileType {
  BusinessRuleSpec = 'BusinessRuleSpec',
  Capability = 'Capability',
  ComponentSpec = 'ComponentSpec',
  Doc = 'Doc',
  Feature = 'Feature',
  FeatureSet = 'FeatureSet',
  Folder = 'Folder',
}

export type InviteUsersArgs = {
  emails: Array<Scalars['String']>;
  organizationId: Scalars['String'];
  resourceId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ShareType>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type LinkExistingSpecToDocArgs = {
  blockId: Scalars['String'];
  docId: Scalars['String'];
  specId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type LinkNewSpecToDocArgs = {
  blockId: Scalars['String'];
  docId: Scalars['String'];
  specId: Scalars['String'];
  specName: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type LinkPreview = {
  __typename?: 'LinkPreview';
  favicon?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Manifest = {
  __typename?: 'Manifest';
  appUrl: Scalars['String'];
  description: Scalars['String'];
  icon: Scalars['String'];
  name: Scalars['String'];
  schemes?: Maybe<Array<Scheme>>;
  settings?: Maybe<Settings>;
  vendor: Vendor;
  version: Scalars['String'];
};

export type MediaAuth = {
  __typename?: 'MediaAuth';
  token: Scalars['String'];
};

export type MentionUsersArgs = {
  commentId: Scalars['String'];
  mentionedUserIds: Array<Scalars['String']>;
  threadId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type ModelVersion = {
  __typename?: 'ModelVersion';
  createdAt: Scalars['DateTimeISO'];
  isLatest: Scalars['Boolean'];
  size: Scalars['Float'];
  versionId: Scalars['ID'];
};

export type MoveFileArgs = {
  destinationParentId?: InputMaybe<Scalars['String']>;
  destinationPrecedingId?: InputMaybe<Scalars['String']>;
  destinationSucceedingId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite: Response;
  addFile: Response;
  addOrganization: Response;
  addWorkspace: Response;
  configureSamlSso: Response;
  continueModelSuggestion: Response;
  deleteFile: Response;
  deleteOrganization: Response;
  deleteWorkspace: Response;
  inviteUsers: Response;
  linkExistingSpecToDoc: Response;
  linkNewSpecToDoc: Response;
  mentionUsers: Response;
  moveFile: Response;
  registerApp: Response;
  removeApp: Response;
  removeUserFromOrganization: Response;
  renameFile: Response;
  renameOrganization: Response;
  renameWorkspace: Response;
  resolveThread: Response;
  restoreVersion: Response;
  suggestModel: Response;
  triggerBuildClean: Response;
  triggerCodeGeneration: Response;
  triggerDeployment: Response;
  triggerImplementation: Response;
  unlinkSpecFromDoc: Response;
  updateAppSettings: Response;
  updateContentSharingScope: Response;
  updateOrganizationEmailDomains: Response;
  updateUserRole: Response;
  verifyEmail: Response;
};

export type MutationAcceptInviteArgs = {
  args: AcceptInviteArgs;
};

export type MutationAddFileArgs = {
  args: AddFileArgs;
};

export type MutationAddOrganizationArgs = {
  args: AddOrganizationArgs;
};

export type MutationAddWorkspaceArgs = {
  args: AddWorkspaceArgs;
};

export type MutationConfigureSamlSsoArgs = {
  args: ConfigureSamlSsoArgs;
};

export type MutationContinueModelSuggestionArgs = {
  args: ContinueModelSuggestionArgs;
};

export type MutationDeleteFileArgs = {
  args: DeleteFileArgs;
};

export type MutationDeleteOrganizationArgs = {
  args: DeleteOrganizationArgs;
};

export type MutationDeleteWorkspaceArgs = {
  args: DeleteWorkspaceArgs;
};

export type MutationInviteUsersArgs = {
  args: InviteUsersArgs;
};

export type MutationLinkExistingSpecToDocArgs = {
  args: LinkExistingSpecToDocArgs;
};

export type MutationLinkNewSpecToDocArgs = {
  args: LinkNewSpecToDocArgs;
};

export type MutationMentionUsersArgs = {
  args: MentionUsersArgs;
};

export type MutationMoveFileArgs = {
  args: MoveFileArgs;
};

export type MutationRegisterAppArgs = {
  args: RegisterAppArgs;
};

export type MutationRemoveAppArgs = {
  args: RemoveAppArgs;
};

export type MutationRemoveUserFromOrganizationArgs = {
  args: RemoveUserFromOrganizationArgs;
};

export type MutationRenameFileArgs = {
  args: RenameFileArgs;
};

export type MutationRenameOrganizationArgs = {
  args: RenameOrganizationArgs;
};

export type MutationRenameWorkspaceArgs = {
  args: RenameWorkspaceArgs;
};

export type MutationResolveThreadArgs = {
  args: ResolveThreadArgs;
};

export type MutationRestoreVersionArgs = {
  args: RestoreVersionArgs;
};

export type MutationSuggestModelArgs = {
  args: SuggestModelArgs;
};

export type MutationTriggerBuildCleanArgs = {
  args: TriggerBuildCleanArgs;
};

export type MutationTriggerCodeGenerationArgs = {
  args: TriggerCodegenArgs;
};

export type MutationTriggerDeploymentArgs = {
  args: TriggerDeploymentArgs;
};

export type MutationTriggerImplementationArgs = {
  args: TriggerImplementationArgs;
};

export type MutationUnlinkSpecFromDocArgs = {
  args: UnlinkSpecFromDocArgs;
};

export type MutationUpdateAppSettingsArgs = {
  args: UpdateAppSettingsArgs;
};

export type MutationUpdateContentSharingScopeArgs = {
  args: UpdateContentSharingScopeArgs;
};

export type MutationUpdateOrganizationEmailDomainsArgs = {
  args: UpdateOrganizationEmailDomainsArgs;
};

export type MutationUpdateUserRoleArgs = {
  args: UpdateUserRoleArgs;
};

export type Option = {
  __typename?: 'Option';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  allowedEmailDomains: Array<OrganizationAllowedEmailDomain>;
  contentSharingScope: ContentSharingScope;
  hasBilling?: Maybe<Scalars['Boolean']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfSeats?: Maybe<Scalars['Float']>;
  registeredApps: Array<RegisteredApp>;
  samlSsoConfig?: Maybe<SamlSsoConfig>;
  singleSourceModel?: Maybe<SingleSourceModel>;
  subscriptionPlans: Array<OrganizationSubscriptionPlan>;
  users?: Maybe<Array<User>>;
  workspaces: Array<Workspace>;
};

export type OrganizationAllowedEmailDomain = {
  __typename?: 'OrganizationAllowedEmailDomain';
  domain: Scalars['String'];
};

export type OrganizationSubscriptionPlan = {
  __typename?: 'OrganizationSubscriptionPlan';
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfSeats: Scalars['Float'];
  status: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  changeset?: Maybe<Changeset>;
  changesetTree: Array<ChangesetTree>;
  changesets: Array<Changeset>;
  customerPortalSession: SubscriptionSession;
  file: File;
  fileSubTree: Array<File>;
  fileTree: Array<File>;
  files: Array<FileChange>;
  linkPreview: LinkPreview;
  mediaAuth: MediaAuth;
  organizations: Array<Organization>;
  search: Array<SearchResult>;
  signedMediaUploadUrl: SignedUrl;
  subscriptionPlans: Array<SubscriptionPlan>;
  subscriptionSession: SubscriptionSession;
  user: User;
  users: Array<User>;
  versions: Array<ModelVersion>;
};

export type QueryChangesetArgs = {
  id: Scalars['String'];
};

export type QueryChangesetTreeArgs = {
  workspaceId: Scalars['String'];
};

export type QueryChangesetsArgs = {
  workspaceId: Scalars['String'];
};

export type QueryCustomerPortalSessionArgs = {
  organizationId: Scalars['String'];
};

export type QueryFileArgs = {
  fileId: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  workspaceId: Scalars['String'];
};

export type QueryFileSubTreeArgs = {
  fileId: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  workspaceId: Scalars['String'];
};

export type QueryFileTreeArgs = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  workspaceId: Scalars['String'];
};

export type QueryFilesArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryLinkPreviewArgs = {
  url: Scalars['String'];
};

export type QuerySearchArgs = {
  excludeFileTypes?: InputMaybe<Array<SearchResultType>>;
  input?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type QuerySignedMediaUploadUrlArgs = {
  parentId: Scalars['String'];
};

export type QuerySubscriptionPlansArgs = {
  organizationId: Scalars['String'];
};

export type QuerySubscriptionSessionArgs = {
  organizationId: Scalars['String'];
  planId: Scalars['String'];
};

export type QueryUsersArgs = {
  organizationId: Scalars['String'];
};

export type QueryVersionsArgs = {
  organizationId: Scalars['String'];
};

export type RegisterAppArgs = {
  appId: Scalars['String'];
  organizationId: Scalars['String'];
  url: Scalars['String'];
};

export type RegisteredApp = {
  __typename?: 'RegisteredApp';
  description?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  manifest?: Maybe<Manifest>;
  name?: Maybe<Scalars['String']>;
  savedSettings?: Maybe<Array<SavedSettings>>;
  url: Scalars['String'];
  vendorName?: Maybe<Scalars['String']>;
  vendorSupportUrl?: Maybe<Scalars['String']>;
  vendorWebsite?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type RemoveAppArgs = {
  appId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type RemoveUserFromOrganizationArgs = {
  organizationId: Scalars['String'];
  userToRemove: Scalars['String'];
};

export type RenameFileArgs = {
  id: Scalars['String'];
  newName: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type RenameOrganizationArgs = {
  newName: Scalars['String'];
  organizationId: Scalars['String'];
};

export type RenameWorkspaceArgs = {
  newName: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type ResolveThreadArgs = {
  id: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type Response = {
  __typename?: 'Response';
  data?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type RestoreVersionArgs = {
  fileVersion: Scalars['String'];
  organizationId: Scalars['String'];
  scope: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER',
}

export type SamlSsoConfig = {
  __typename?: 'SamlSsoConfig';
  idp: Scalars['String'];
  tenantId: Scalars['String'];
};

export type SavedSettings = {
  __typename?: 'SavedSettings';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Scheme = {
  __typename?: 'Scheme';
  categories: Array<EntityCategory>;
  name: Scalars['String'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  fileType: SearchResultType;
  id: Scalars['ID'];
  path: Scalars['String'];
  title: Scalars['String'];
};

export enum SearchResultType {
  BusinessRuleSpec = 'BusinessRuleSpec',
  Capability = 'Capability',
  Changeset = 'Changeset',
  ComponentSpec = 'ComponentSpec',
  Doc = 'Doc',
  Feature = 'Feature',
  FeatureSet = 'FeatureSet',
  Folder = 'Folder',
}

export type SelectionsInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  subSelections?: InputMaybe<Array<SelectionsInput>>;
};

export type SettingInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  fields: Array<SettingsField>;
};

export type SettingsField = {
  __typename?: 'SettingsField';
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: SettingsFieldType;
};

export enum SettingsFieldType {
  Boolean = 'Boolean',
  Number = 'Number',
  Text = 'Text',
}

export enum ShareType {
  Diagram = 'Diagram',
  Doc = 'Doc',
  Organization = 'Organization',
  Spec = 'Spec',
}

export type SignedUrl = {
  __typename?: 'SignedUrl';
  getUrl: Scalars['String'];
  putUrl: Scalars['String'];
};

export type SingleSourceModel = {
  __typename?: 'SingleSourceModel';
  id: Scalars['ID'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  canSubscribe: Scalars['Boolean'];
  currency: Scalars['String'];
  description: Scalars['String'];
  features: Array<Scalars['String']>;
  id: Scalars['ID'];
  isCurrentPlan: Scalars['Boolean'];
  isCustomPlan: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['Float'];
  priceFormatted: Scalars['String'];
  priceInterval: Scalars['String'];
};

export type SubscriptionSession = {
  __typename?: 'SubscriptionSession';
  url: Scalars['String'];
};

export type SuggestModelArgs = {
  clientId: Scalars['String'];
  input: Scalars['String'];
  modelId: Scalars['String'];
  modelType: Scalars['String'];
  organizationId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type TriggerBuildCleanArgs = {
  branch: Scalars['String'];
  buildId: Scalars['String'];
  modelId: Scalars['String'];
  organizationId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type TriggerCodegenArgs = {
  branch: Scalars['String'];
  buildId: Scalars['String'];
  modelId: Scalars['String'];
  modelType: Scalars['String'];
  organizationId: Scalars['String'];
  payload: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type TriggerDeploymentArgs = {
  branch: Scalars['String'];
  buildId: Scalars['String'];
  modelId: Scalars['String'];
  organizationId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type TriggerImplementationArgs = {
  branch: Scalars['String'];
  buildId: Scalars['String'];
  modelId: Scalars['String'];
  organizationId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type UnlinkSpecFromDocArgs = {
  docId: Scalars['String'];
  specId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type UpdateAppSettingsArgs = {
  appId: Scalars['String'];
  organizationId: Scalars['String'];
  settings: Array<SettingInput>;
};

export type UpdateContentSharingScopeArgs = {
  organizationId: Scalars['String'];
  sharingScope: ContentSharingScope;
};

export type UpdateOrganizationEmailDomainsArgs = {
  domains: Array<Scalars['String']>;
  organizationId: Scalars['String'];
};

export type UpdateUserRoleArgs = {
  organizationId: Scalars['String'];
  role: Role;
  userToUpdate: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permittedOrganizations: Array<UserOrganization>;
  picture?: Maybe<Scalars['String']>;
  role: Role;
};

export type UserOrganization = {
  __typename?: 'UserOrganization';
  organization: Organization;
  role: Role;
};

export type Vendor = {
  __typename?: 'Vendor';
  name: Scalars['String'];
  supportUrl: Scalars['String'];
  website: Scalars['String'];
};

export type Workspace = {
  __typename?: 'Workspace';
  changesets: Array<Changeset>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AddOrganizationMutationVariables = Exact<{
  args: AddOrganizationArgs;
}>;

export type AddOrganizationMutation = {
  __typename?: 'Mutation';
  addOrganization: { __typename?: 'Response'; error?: string | null };
};

export type AddWorkspaceMutationVariables = Exact<{
  args: AddWorkspaceArgs;
}>;

export type AddWorkspaceMutation = {
  __typename?: 'Mutation';
  addWorkspace: { __typename?: 'Response'; error?: string | null };
};

export type InviteUsersMutationVariables = Exact<{
  args: InviteUsersArgs;
}>;

export type InviteUsersMutation = {
  __typename?: 'Mutation';
  inviteUsers: { __typename?: 'Response'; error?: string | null };
};

export type VerifyEmailMutationVariables = Exact<{ [key: string]: never }>;

export type VerifyEmailMutation = {
  __typename?: 'Mutation';
  verifyEmail: { __typename?: 'Response'; error?: string | null };
};

export type AcceptInviteMutationVariables = Exact<{
  args: AcceptInviteArgs;
}>;

export type AcceptInviteMutation = {
  __typename?: 'Mutation';
  acceptInvite: { __typename?: 'Response'; error?: string | null };
};

export type LinkNewSpecToDocMutationVariables = Exact<{
  args: LinkNewSpecToDocArgs;
}>;

export type LinkNewSpecToDocMutation = {
  __typename?: 'Mutation';
  linkNewSpecToDoc: { __typename?: 'Response'; error?: string | null };
};

export type LinkExistingSpecToDocMutationVariables = Exact<{
  args: LinkExistingSpecToDocArgs;
}>;

export type LinkExistingSpecToDocMutation = {
  __typename?: 'Mutation';
  linkExistingSpecToDoc: { __typename?: 'Response'; error?: string | null };
};

export type UnlinkSpecFromDocMutationVariables = Exact<{
  args: UnlinkSpecFromDocArgs;
}>;

export type UnlinkSpecFromDocMutation = {
  __typename?: 'Mutation';
  unlinkSpecFromDoc: { __typename?: 'Response'; error?: string | null };
};

export type DeleteWorkspaceMutationVariables = Exact<{
  args: DeleteWorkspaceArgs;
}>;

export type DeleteWorkspaceMutation = {
  __typename?: 'Mutation';
  deleteWorkspace: { __typename?: 'Response'; error?: string | null };
};

export type RenameWorkspaceMutationVariables = Exact<{
  args: RenameWorkspaceArgs;
}>;

export type RenameWorkspaceMutation = {
  __typename?: 'Mutation';
  renameWorkspace: { __typename?: 'Response'; error?: string | null };
};

export type DeleteOrganizationMutationVariables = Exact<{
  args: DeleteOrganizationArgs;
}>;

export type DeleteOrganizationMutation = {
  __typename?: 'Mutation';
  deleteOrganization: { __typename?: 'Response'; error?: string | null };
};

export type RenameOrganizationMutationVariables = Exact<{
  args: RenameOrganizationArgs;
}>;

export type RenameOrganizationMutation = {
  __typename?: 'Mutation';
  renameOrganization: { __typename?: 'Response'; error?: string | null };
};

export type UpdateUserRoleMutationVariables = Exact<{
  args: UpdateUserRoleArgs;
}>;

export type UpdateUserRoleMutation = {
  __typename?: 'Mutation';
  updateUserRole: { __typename?: 'Response'; error?: string | null };
};

export type RemoveUserFromOrganizationMutationVariables = Exact<{
  args: RemoveUserFromOrganizationArgs;
}>;

export type RemoveUserFromOrganizationMutation = {
  __typename?: 'Mutation';
  removeUserFromOrganization: { __typename?: 'Response'; error?: string | null };
};

export type UpdateOrganizationEmailDomainsMutationVariables = Exact<{
  args: UpdateOrganizationEmailDomainsArgs;
}>;

export type UpdateOrganizationEmailDomainsMutation = {
  __typename?: 'Mutation';
  updateOrganizationEmailDomains: { __typename?: 'Response'; error?: string | null };
};

export type RenameFileMutationVariables = Exact<{
  args: RenameFileArgs;
}>;

export type RenameFileMutation = {
  __typename?: 'Mutation';
  renameFile: { __typename?: 'Response'; error?: string | null };
};

export type MoveFileMutationVariables = Exact<{
  args: MoveFileArgs;
}>;

export type MoveFileMutation = {
  __typename?: 'Mutation';
  moveFile: { __typename?: 'Response'; error?: string | null };
};

export type AddFileMutationVariables = Exact<{
  args: AddFileArgs;
}>;

export type AddFileMutation = { __typename?: 'Mutation'; addFile: { __typename?: 'Response'; error?: string | null } };

export type DeleteFileMutationVariables = Exact<{
  args: DeleteFileArgs;
}>;

export type DeleteFileMutation = {
  __typename?: 'Mutation';
  deleteFile: { __typename?: 'Response'; error?: string | null };
};

export type MentionUsersMutationVariables = Exact<{
  args: MentionUsersArgs;
}>;

export type MentionUsersMutation = {
  __typename?: 'Mutation';
  mentionUsers: { __typename?: 'Response'; error?: string | null };
};

export type ResolveThreadMutationVariables = Exact<{
  args: ResolveThreadArgs;
}>;

export type ResolveThreadMutation = {
  __typename?: 'Mutation';
  resolveThread: { __typename?: 'Response'; data?: string | null; error?: string | null };
};

export type RestoreVersionMutationVariables = Exact<{
  args: RestoreVersionArgs;
}>;

export type RestoreVersionMutation = {
  __typename?: 'Mutation';
  restoreVersion: { __typename?: 'Response'; error?: string | null };
};

export type UpdateContentSharingScopeMutationVariables = Exact<{
  args: UpdateContentSharingScopeArgs;
}>;

export type UpdateContentSharingScopeMutation = {
  __typename?: 'Mutation';
  updateContentSharingScope: { __typename?: 'Response'; error?: string | null };
};

export type RegisterAppMutationVariables = Exact<{
  args: RegisterAppArgs;
}>;

export type RegisterAppMutation = {
  __typename?: 'Mutation';
  registerApp: { __typename?: 'Response'; data?: string | null; error?: string | null };
};

export type RemoveAppMutationVariables = Exact<{
  args: RemoveAppArgs;
}>;

export type RemoveAppMutation = {
  __typename?: 'Mutation';
  removeApp: { __typename?: 'Response'; data?: string | null; error?: string | null };
};

export type UpdateAppSettingsMutationVariables = Exact<{
  args: UpdateAppSettingsArgs;
}>;

export type UpdateAppSettingsMutation = {
  __typename?: 'Mutation';
  updateAppSettings: { __typename?: 'Response'; data?: string | null; error?: string | null };
};

export type TriggerCodeGenerationMutationVariables = Exact<{
  args: TriggerCodegenArgs;
}>;

export type TriggerCodeGenerationMutation = {
  __typename?: 'Mutation';
  triggerCodeGeneration: { __typename?: 'Response'; error?: string | null; data?: string | null };
};

export type TriggerImplementationMutationVariables = Exact<{
  args: TriggerImplementationArgs;
}>;

export type TriggerImplementationMutation = {
  __typename?: 'Mutation';
  triggerImplementation: { __typename?: 'Response'; error?: string | null; data?: string | null };
};

export type TriggerDeploymentMutationVariables = Exact<{
  args: TriggerDeploymentArgs;
}>;

export type TriggerDeploymentMutation = {
  __typename?: 'Mutation';
  triggerDeployment: { __typename?: 'Response'; data?: string | null; error?: string | null };
};

export type TriggerBuildCleanMutationVariables = Exact<{
  args: TriggerBuildCleanArgs;
}>;

export type TriggerBuildCleanMutation = {
  __typename?: 'Mutation';
  triggerBuildClean: { __typename?: 'Response'; data?: string | null; error?: string | null };
};

export type SuggestModelMutationVariables = Exact<{
  args: SuggestModelArgs;
}>;

export type SuggestModelMutation = {
  __typename?: 'Mutation';
  suggestModel: { __typename?: 'Response'; error?: string | null; data?: string | null };
};

export type ContinueModelSuggestionMutationVariables = Exact<{
  args: ContinueModelSuggestionArgs;
}>;

export type ContinueModelSuggestionMutation = {
  __typename?: 'Mutation';
  continueModelSuggestion: { __typename?: 'Response'; error?: string | null; data?: string | null };
};

export type OrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationsQuery = {
  __typename?: 'Query';
  organizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    hasBilling?: boolean | null;
    hasSubscription?: boolean | null;
    numberOfSeats?: number | null;
    contentSharingScope: ContentSharingScope;
    allowedEmailDomains: Array<{ __typename?: 'OrganizationAllowedEmailDomain'; domain: string }>;
    workspaces: Array<{ __typename?: 'Workspace'; id: string; name: string }>;
    subscriptionPlans: Array<{
      __typename?: 'OrganizationSubscriptionPlan';
      id: string;
      name: string;
      status: string;
      numberOfSeats: number;
    }>;
    samlSsoConfig?: { __typename?: 'SamlSsoConfig'; tenantId: string; idp: string } | null;
    users?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email?: string | null;
      picture?: string | null;
      role: Role;
      permittedOrganizations: Array<{
        __typename?: 'UserOrganization';
        role: Role;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
          hasBilling?: boolean | null;
          hasSubscription?: boolean | null;
          numberOfSeats?: number | null;
          contentSharingScope: ContentSharingScope;
        };
      }>;
    }> | null;
    singleSourceModel?: { __typename?: 'SingleSourceModel'; id: string } | null;
    registeredApps: Array<{
      __typename?: 'RegisteredApp';
      id: string;
      name?: string | null;
      description?: string | null;
      icon?: string | null;
      version?: string | null;
      vendorName?: string | null;
      vendorWebsite?: string | null;
      vendorSupportUrl?: string | null;
      url: string;
      error?: string | null;
      manifest?: {
        __typename?: 'Manifest';
        name: string;
        description: string;
        icon: string;
        version: string;
        appUrl: string;
        vendor: { __typename?: 'Vendor'; name: string; website: string; supportUrl: string };
        schemes?: Array<{
          __typename?: 'Scheme';
          name: string;
          categories: Array<{
            __typename?: 'EntityCategory';
            name: string;
            constructs?: Array<{
              __typename?: 'ConstructEntity';
              type: string;
              label: string;
              description: string;
              backgroundColor: string;
              textColor: string;
              shape: string;
            }> | null;
            assets?: Array<{
              __typename?: 'AssetEntity';
              label: string;
              description: string;
              type: string;
              icon: string;
              dataSource: string;
              detailsPane?: Array<{
                __typename?: 'DetailsElement';
                type: DetailsElementType;
                label?: string | null;
                value?: string | null;
                elements?: Array<{
                  __typename?: 'DetailsElement';
                  type: DetailsElementType;
                  label?: string | null;
                  elements?: Array<{
                    __typename?: 'DetailsElement';
                    type: DetailsElementType;
                    label?: string | null;
                  }> | null;
                }> | null;
                options?: Array<{ __typename?: 'Option'; value: string; label: string }> | null;
              }> | null;
            }> | null;
          }>;
        }> | null;
        settings?: {
          __typename?: 'Settings';
          fields: Array<{
            __typename?: 'SettingsField';
            name: string;
            label: string;
            type: SettingsFieldType;
            description?: string | null;
            required?: boolean | null;
            defaultValue?: string | null;
          }>;
        } | null;
      } | null;
      savedSettings?: Array<{ __typename?: 'SavedSettings'; name?: string | null; value?: string | null }> | null;
    }>;
  }>;
};

export type CustomerPortalSessionQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type CustomerPortalSessionQuery = {
  __typename?: 'Query';
  customerPortalSession: { __typename?: 'SubscriptionSession'; url: string };
};

export type FileTreeQueryVariables = Exact<{
  workspaceId: Scalars['String'];
}>;

export type FileTreeQuery = {
  __typename?: 'Query';
  fileTree: Array<{
    __typename?: 'File';
    id: string;
    name: string;
    fileType: FileType;
    children?: Array<{ __typename?: 'File'; id: string; name: string; fileType: FileType }> | null;
    parent?: { __typename?: 'File'; id: string; name: string; fileType: FileType } | null;
  }>;
};

export type FileSubTreeQueryVariables = Exact<{
  fileId: Scalars['String'];
  workspaceId: Scalars['String'];
}>;

export type FileSubTreeQuery = {
  __typename?: 'Query';
  fileSubTree: Array<{
    __typename?: 'File';
    id: string;
    name: string;
    fileType: FileType;
    children?: Array<{ __typename?: 'File'; id: string; name: string; fileType: FileType }> | null;
    parent?: { __typename?: 'File'; id: string; name: string; fileType: FileType } | null;
  }>;
};

export type FileQueryVariables = Exact<{
  workspaceId: Scalars['String'];
  fileId: Scalars['String'];
}>;

export type FileQuery = {
  __typename?: 'Query';
  file: {
    __typename?: 'File';
    id: string;
    name: string;
    fileType: FileType;
    children?: Array<{ __typename?: 'File'; id: string; name: string; fileType: FileType }> | null;
    parent?: { __typename?: 'File'; id: string; name: string; fileType: FileType } | null;
  };
};

export type MediaAuthQueryVariables = Exact<{ [key: string]: never }>;

export type MediaAuthQuery = { __typename?: 'Query'; mediaAuth: { __typename?: 'MediaAuth'; token: string } };

export type ChangesetTreeQueryVariables = Exact<{
  workspaceId: Scalars['String'];
}>;

export type ChangesetTreeQuery = {
  __typename?: 'Query';
  changesetTree: Array<{
    __typename?: 'ChangesetTree';
    id: string;
    name: string;
    type: ChangesetTreeType;
    isPublished: boolean;
    parent?: {
      __typename?: 'ChangesetTree';
      id: string;
      name: string;
      type: ChangesetTreeType;
      isPublished: boolean;
    } | null;
  }>;
};

export type SearchQueryVariables = Exact<{
  workspaceId: Scalars['String'];
  input?: InputMaybe<Scalars['String']>;
  excludeFileTypes?: InputMaybe<Array<SearchResultType> | SearchResultType>;
}>;

export type SearchQuery = {
  __typename?: 'Query';
  search: Array<{ __typename?: 'SearchResult'; fileType: SearchResultType; id: string; path: string; title: string }>;
};

export type FilesQueryVariables = Exact<{
  fileIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type FilesQuery = {
  __typename?: 'Query';
  files: Array<{
    __typename?: 'FileChange';
    id: string;
    name: string;
    fileType: FileType;
    committedChanges?: Array<{
      __typename?: 'CommittedChange';
      blockId: string;
      line?: number | null;
      path: Array<number>;
      content?: string | null;
      changeType?: ChangeType | null;
      changeset: { __typename?: 'Changeset'; id: string; name: string };
    }> | null;
    publishedChanges: Array<{
      __typename?: 'Change';
      line?: number | null;
      path?: Array<number> | null;
      blockId: string;
      content?: string | null;
    }>;
  }>;
};

export type ChangesetQueryVariables = Exact<{
  changesetId: Scalars['String'];
}>;

export type ChangesetQuery = {
  __typename?: 'Query';
  changeset?: {
    __typename?: 'Changeset';
    name: string;
    isPublished: boolean;
    files?: Array<{
      __typename?: 'FileChange';
      fileType: FileType;
      id: string;
      name: string;
      committedChanges?: Array<{
        __typename?: 'CommittedChange';
        blockId: string;
        line?: number | null;
        path: Array<number>;
        content?: string | null;
        changeType?: ChangeType | null;
      }> | null;
      publishedChanges: Array<{
        __typename?: 'Change';
        line?: number | null;
        path?: Array<number> | null;
        blockId: string;
        content?: string | null;
      }>;
    }> | null;
  } | null;
};

export type UsersQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    email?: string | null;
    id: string;
    name?: string | null;
    picture?: string | null;
    role: Role;
  }>;
};

export type SignedUploadUrlQueryQueryVariables = Exact<{
  parentId: Scalars['String'];
}>;

export type SignedUploadUrlQueryQuery = {
  __typename?: 'Query';
  signedMediaUploadUrl: { __typename: 'SignedUrl'; putUrl: string; getUrl: string };
};

export type SubscriptionPlansQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type SubscriptionPlansQuery = {
  __typename?: 'Query';
  subscriptionPlans: Array<{
    __typename?: 'SubscriptionPlan';
    id: string;
    name: string;
    description: string;
    features: Array<string>;
    price: number;
    currency: string;
    priceInterval: string;
    canSubscribe: boolean;
    isCustomPlan: boolean;
    isCurrentPlan: boolean;
  }>;
};

export type SubscriptionSessionQueryVariables = Exact<{
  organizationId: Scalars['String'];
  planId: Scalars['String'];
}>;

export type SubscriptionSessionQuery = {
  __typename?: 'Query';
  subscriptionSession: { __typename?: 'SubscriptionSession'; url: string };
};

export type VersionsQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type VersionsQuery = {
  __typename?: 'Query';
  versions: Array<{ __typename?: 'ModelVersion'; versionId: string; createdAt: any; isLatest: boolean; size: number }>;
};

export type LinkPreviewQueryVariables = Exact<{
  url: Scalars['String'];
}>;

export type LinkPreviewQuery = {
  __typename?: 'Query';
  linkPreview: { __typename?: 'LinkPreview'; title: string; imageUrl?: string | null; favicon?: string | null };
};

export const AddOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddOrganizationArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddOrganizationMutation, AddOrganizationMutationVariables>;
export const AddWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddWorkspaceArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddWorkspaceMutation, AddWorkspaceMutationVariables>;
export const InviteUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'inviteUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InviteUsersArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteUsersMutation, InviteUsersMutationVariables>;
export const VerifyEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyEmail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyEmail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const AcceptInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AcceptInviteArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const LinkNewSpecToDocDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'linkNewSpecToDoc' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LinkNewSpecToDocArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkNewSpecToDoc' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LinkNewSpecToDocMutation, LinkNewSpecToDocMutationVariables>;
export const LinkExistingSpecToDocDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'linkExistingSpecToDoc' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LinkExistingSpecToDocArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkExistingSpecToDoc' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LinkExistingSpecToDocMutation, LinkExistingSpecToDocMutationVariables>;
export const UnlinkSpecFromDocDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'unlinkSpecFromDoc' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UnlinkSpecFromDocArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unlinkSpecFromDoc' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnlinkSpecFromDocMutation, UnlinkSpecFromDocMutationVariables>;
export const DeleteWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteWorkspaceArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;
export const RenameWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'renameWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RenameWorkspaceArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renameWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameWorkspaceMutation, RenameWorkspaceMutationVariables>;
export const DeleteOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteOrganizationArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const RenameOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'renameOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RenameOrganizationArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renameOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameOrganizationMutation, RenameOrganizationMutationVariables>;
export const UpdateUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserRoleArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const RemoveUserFromOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeUserFromOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RemoveUserFromOrganizationArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserFromOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>;
export const UpdateOrganizationEmailDomainsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganizationEmailDomains' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateOrganizationEmailDomainsArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganizationEmailDomains' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrganizationEmailDomainsMutation, UpdateOrganizationEmailDomainsMutationVariables>;
export const RenameFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'renameFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'RenameFileArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renameFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameFileMutation, RenameFileMutationVariables>;
export const MoveFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'moveFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MoveFileArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoveFileMutation, MoveFileMutationVariables>;
export const AddFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddFileArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddFileMutation, AddFileMutationVariables>;
export const DeleteFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteFileArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFileMutation, DeleteFileMutationVariables>;
export const MentionUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'mentionUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MentionUsersArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentionUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MentionUsersMutation, MentionUsersMutationVariables>;
export const ResolveThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resolveThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResolveThreadArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resolveThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResolveThreadMutation, ResolveThreadMutationVariables>;
export const RestoreVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'restoreVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RestoreVersionArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestoreVersionMutation, RestoreVersionMutationVariables>;
export const UpdateContentSharingScopeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateContentSharingScope' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateContentSharingScopeArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContentSharingScope' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateContentSharingScopeMutation, UpdateContentSharingScopeMutationVariables>;
export const RegisterAppDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'registerApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'RegisterAppArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerApp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegisterAppMutation, RegisterAppMutationVariables>;
export const RemoveAppDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'RemoveAppArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeApp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveAppMutation, RemoveAppMutationVariables>;
export const UpdateAppSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAppSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAppSettingsArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAppSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAppSettingsMutation, UpdateAppSettingsMutationVariables>;
export const TriggerCodeGenerationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'triggerCodeGeneration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TriggerCodegenArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerCodeGeneration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriggerCodeGenerationMutation, TriggerCodeGenerationMutationVariables>;
export const TriggerImplementationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'triggerImplementation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TriggerImplementationArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerImplementation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriggerImplementationMutation, TriggerImplementationMutationVariables>;
export const TriggerDeploymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'triggerDeployment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TriggerDeploymentArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerDeployment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriggerDeploymentMutation, TriggerDeploymentMutationVariables>;
export const TriggerBuildCleanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'triggerBuildClean' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TriggerBuildCleanArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerBuildClean' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriggerBuildCleanMutation, TriggerBuildCleanMutationVariables>;
export const SuggestModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'suggestModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SuggestModelArgs' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suggestModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SuggestModelMutation, SuggestModelMutationVariables>;
export const ContinueModelSuggestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'continueModelSuggestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContinueModelSuggestionArgs' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'continueModelSuggestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContinueModelSuggestionMutation, ContinueModelSuggestionMutationVariables>;
export const OrganizationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'organizations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBilling' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasSubscription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowedEmailDomains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'domain' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspaces' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptionPlans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'samlSsoConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'tenantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'idp' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'permittedOrganizations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'organization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasBilling' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasSubscription' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentSharingScope' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'singleSourceModel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contentSharingScope' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registeredApps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vendorName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vendorWebsite' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vendorSupportUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manifest' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'appUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vendor' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'supportUrl' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'schemes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'categories' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'constructs' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'shape' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'assets' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'dataSource' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'detailsPane' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'elements' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                                          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'elements' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'type' },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'label' },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'options' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                                          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'settings' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fields' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationsQuery, OrganizationsQueryVariables>;
export const CustomerPortalSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'customerPortalSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerPortalSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerPortalSessionQuery, CustomerPortalSessionQueryVariables>;
export const FileTreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fileTree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileTree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileTreeQuery, FileTreeQueryVariables>;
export const FileSubTreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fileSubTree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileSubTree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileSubTreeQuery, FileSubTreeQueryVariables>;
export const FileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'file' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileQuery, FileQueryVariables>;
export const MediaAuthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'mediaAuth' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaAuth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'token' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MediaAuthQuery, MediaAuthQueryVariables>;
export const ChangesetTreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'changesetTree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changesetTree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPublished' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPublished' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangesetTreeQuery, ChangesetTreeQueryVariables>;
export const SearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'search' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'excludeFileTypes' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchResultType' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workspaceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludeFileTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'excludeFileTypes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchQuery, SearchQueryVariables>;
export const FilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'files' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'committedChanges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'blockId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'line' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'changeType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changeset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publishedChanges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'line' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blockId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilesQuery, FilesQueryVariables>;
export const ChangesetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'changeset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'changesetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'changesetId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPublished' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'committedChanges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'blockId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'line' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'changeType' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publishedChanges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'line' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'blockId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangesetQuery, ChangesetQueryVariables>;
export const UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'users' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const SignedUploadUrlQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'signedUploadUrlQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signedMediaUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'putUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'getUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignedUploadUrlQueryQuery, SignedUploadUrlQueryQueryVariables>;
export const SubscriptionPlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'subscriptionPlans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionPlans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceInterval' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canSubscribe' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustomPlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCurrentPlan' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>;
export const SubscriptionSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'subscriptionSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionSessionQuery, SubscriptionSessionQueryVariables>;
export const VersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'versions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLatest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VersionsQuery, VersionsQueryVariables>;
export const LinkPreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'linkPreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkPreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'url' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favicon' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LinkPreviewQuery, LinkPreviewQueryVariables>;
